<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="df-aic-jcsb">
          <img src="../assets/logo.png" style="width: 100px;height: 50px;">
          <el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" route="/introduction">简介</el-menu-item>
            <el-menu-item index="2" route="/business">业务</el-menu-item>
            <el-menu-item index="3" route="/about">关于我们</el-menu-item>
          </el-menu>
        </div>
      </el-header>
      <el-main>
        <el-carousel height="550px">
          <el-carousel-item v-for="item in swipe" :key="item">
            <el-image style="width: 100%; height: 100%" :src="item" :fit="fit"></el-image>
          </el-carousel-item>
        </el-carousel>
      </el-main>
      <el-footer>
        <div class="footer-info">
          <a href="https://beian.miit.gov.cn">桂ICP备2024037730号-1</a>
          <p>Copyright © 2024 Tenonece. All Rights Reserved. 腾网字公司 版权所有</p>
        </div>
        <div class="footer-contact">
          <p>联系我们：tenonece@qq.com</p>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      activeIndex: '1',
      swipe: [
        "https://tse3-mm.cn.bing.net/th/id/OIP-C.u_8q93mlpEMKqdpRRazXfQHaDc?w=330&h=162&c=7&r=0&o=5&pid=1.7",
        "https://tse3-mm.cn.bing.net/th/id/OIP-C.ABrpLINXx8e81W-gjXZ9WQHaEJ?w=304&h=180&c=7&r=0&o=5&pid=1.7",
        "https://tse2-mm.cn.bing.net/th/id/OIP-C.IDMsAaQFM_fsLZxffBDTQQHaDs?w=314&h=180&c=7&r=0&o=5&pid=1.7"
      ]
    }
  },
};
</script>
<style scoped>
  .el-header, .el-footer {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
</style>

<style scoped>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
</style>

<style scoped>
/* 底部整体样式 */
footer {
  background-color: #f2f2f2;
  padding: 20px;
  text-align: center;
}
 
/* 导航链接样式 */
.footer-nav ul {
  list-style-type: none;
  padding: 0;
}
 
.footer-nav ul li {
  display: inline-block;
  margin-right: 10px;
}
 
.footer-nav ul li a {
  text-decoration: none;
  color: #333;
}
 
/* 版权信息样式 */
.footer-info {
  margin: 10px 0;
}
 
.footer-info p {
  font-size: 12px;
  color: #888;
}
 
/* 社交媒体链接样式 */
.footer-social ul {
  list-style-type: none;
  padding: 0;
}
 
.footer-social ul li {
  display: inline-block;
  margin-right: 10px;
}
 
.footer-social ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}
 
/* 联系信息样式 */
.footer-contact p {
  font-size: 12px;
  color: #888;
  margin: 5px 0;
}
 
/* 返回顶部按钮样式 */
.footer-back-to-top a {
  text-decoration: none;
  color: #333;
  font-size: 12px;
}
 
.footer-back-to-top a:hover {
  text-decoration: underline;
}
</style>